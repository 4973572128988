import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { addToCart, decreaseCart, removeFromCart, clearCart, getTotals } from '../features/cartSlice';

import CryptoJS from 'crypto-js';

const Cart = () => {
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();

    const apiKey2 = process.env.REACT_APP_ID_KEY;
    const apiKey = "VwDZwySKn_eaauYt_-sc6kXx-yr-3tLkR-900pwOGvc";
    const secretKey2 = process.env.REACT_APP_SECRET_KEY;
    const secretKey = "ie9_E7AY-juVW0R1F60PUA"

    console.log("API KEY", apiKey)
    console.log("SECRET KEY", secretKey)

    console.log("API KEY", apiKey2)
    console.log("SECRET KEY", secretKey2)

    useEffect(() => {
        dispatch(getTotals())
    }, [cart, dispatch])

    const handleRemoveFromCart = (cartItem) => {
        dispatch(removeFromCart(cartItem))
    }
    const handleDecreaseCart = (cartItem) => {
        dispatch(decreaseCart(cartItem))
    }
    const handleIncreaseCart = (cartItem) => {
        dispatch(addToCart(cartItem))
    }
    const handleClearCart = () => {
        dispatch(clearCart())
    }

    const handleCheckout = async (cart) => {
        const identifier = crypto.randomUUID();

        /* Crear Unique ID con SHA256 */
        // Convertir el carrito de compras a una cadena JSON
        //const cartDataStr = JSON.stringify(cart);

        // Obtener la marca de tiempo actual
        //const timestamp = Date.now().toString();

        // Crear una cadena única combinando la cadena JSON y la marca de tiempo
        //const uniqueString = cartDataStr + timestamp;

        // Generar un hash SHA-256 de la cadena única
        //const uniqueId = CryptoJS.SHA256(uniqueString).toString(CryptoJS.enc.Hex);
         /* Crear Unique ID con SHA256 */


        const uniqueSalesIdentifier = identifier+cart.cartTotalAmount+"COP"+secretKey;

        //const encriptId = CryptoJS.SHA256(uniqueSalesIdentifier).toString(CryptoJS.enc.Hex);
        //console.log("encriptId --->", encriptId);

        const encondedText = new TextEncoder().encode(uniqueSalesIdentifier);
        const hashBuffer = await crypto.subtle.digest('SHA-256', encondedText);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

        const checkout = new  window.BoldCheckout({
            orderId: identifier,
            currency: "COP",
            amount: cart.cartTotalAmount,
            apiKey: apiKey,
            redirectionUrl: "http://localhost:3000/thanks",
            integritySignature: hashHex,
            // description: "Camiseta Rolling Stones XL",
            // tax: "vat-19",
        });
        debugger
        checkout.open();
    }

    return (
        <div className="cart-container">
            { cart.cartItems.length === 0 ? (
                <>
                    <h2>Shopping Cart</h2>
                    <div className="cart-empty">
                        <p>Your cart is cuurently empty</p>
                        <div className="start-shopping">
                            <Link to="/">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
                                </svg>
                                <span>Start Shopping</span>
                            </Link>
                        </div>
                    </div>
                </>
            ) : (
                <>                    
                    <div>
                        <h2>Shopping Cart</h2>
                        <div className="titles">
                            <h3 className="product-title">Product</h3>
                            <div></div>
                            <div></div>
                            <h3 className="price">Price</h3>
                            <h3 className="quantity">Quantity</h3>
                            <h3 className="total">Subtotal</h3>
                        </div>
                        <div className="cart-items">
                            {cart.cartItems?.map(cartItem => (
                                <div className="cart-item" key={cartItem.id}>
                                    <div className="cart-product-img">
                                        {/* <img src={`http://localhost:5001/${cartItem.mainImage}`} alt={cartItem.name} /> */}
                                        <img src={`${process.env.PUBLIC_URL}/${cartItem.mainImage}`} alt={cartItem.name} />
                                        {/* <img src={`https://shopping-card-backend-b6dx.onrender.com/${cartItem.mainImage}`} alt={cartItem.name} /> */}
                                    </div>
                                    <div className="cart-content">
                                        <div className="cart-product">
                                            <h3>{cartItem.name}</h3>
                                            <p>{cartItem.desc}</p>                                            
                                        </div>
                                        {/* <button onClick={() => handleRemoveFromCart(cartItem)}>Remove</button> */}
                                        <button onClick={() => handleRemoveFromCart(cartItem)} className="cart-delete">
                                            <span class="material-symbols-outlined">delete</span>
                                        </button>
                                        <div className="cart-product-price">${cartItem.price}</div>
                                        <div className="cart-product-quantity">
                                            {/* <button onClick={() => handleDecreaseCart(cartItem)}>-</button> */}
                                            <div className="quantity-content">
                                                <button onClick={() => handleDecreaseCart(cartItem)}>
                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width=".7em" height=".7em" viewBox="0 0 24 24">
                                                        <path d="M23.52 11.4h-23.040c-0.264 0-0.48 0.216-0.48 0.48v0.24c0 0.264 0.216 0.48 0.48 0.48h23.040c0.264 0 0.48-0.216 0.48-0.48v-0.24c0-0.264-0.216-0.48-0.48-0.48z"></path>
                                                    </svg>
                                                </button>
                                                <div className="count">{cartItem.cartQuantity}</div>
                                                {/* <button onClick={() => handleIncreaseCart(cartItem)}>+</button> */}
                                                <button onClick={() => handleIncreaseCart(cartItem)}>
                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width=".7em" height=".7em" viewBox="0 0 24 24">
                                                        <path d="M23.52 11.4h-10.92v-10.92c0-0.264-0.216-0.48-0.48-0.48h-0.24c-0.264 0-0.48 0.216-0.48 0.48v10.92h-10.92c-0.264 0-0.48 0.216-0.48 0.48v0.24c0 0.264 0.216 0.48 0.48 0.48h10.92v10.92c0 0.264 0.216 0.48 0.48 0.48h0.24c0.264 0 0.48-0.216 0.48-0.48v-10.92h10.92c0.264 0 0.48-0.216 0.48-0.48v-0.24c0-0.264-0.216-0.48-0.48-0.48z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                            <div className="cart-product-price-mobile">x ${cartItem.price}</div>
                                        </div>
                                        <div className="cart-product-total-price">
                                            <span className="sutbtotal">Subtotal: </span> ${cartItem.price * cartItem.cartQuantity}
                                        </div>
                                    </div>                                   
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="cart-summary">
                        <div className="cart-checkout">
                            <div className="subtotal">
                                <span>Subtotal</span>
                                <span className="amount">${cart.cartTotalAmount}</span>
                            </div>
                            <p>Taxes and shipping calculate at checkout</p>
                            {/* <button onClick={() => handleCheckout(cart)} >Checkout</button> */}
                            <button id="custom-button" className="custom-styles" onClick={() => handleCheckout(cart)}>Pagar ahora</button>
                            <div className="continue-shopping">
                                <Link to="/">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
                                    </svg>
                                    <span>Continue Shopping</span>
                                </Link>
                                <button className="clear-cart" onClick={() => handleClearCart()}>Clear Cart</button>
                            </div>
                            
                        </div>
                    </div>
                </>                
            ) }
        </div>
    );
}
 
export default Cart;