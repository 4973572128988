import React, { useState, useEffect } from 'react';
import "./ProgressBarBox.scss"

const ProgressBarBox = ({ progressVal, quantity, sold }) => {
    const [ bgColor, setBgColor ] = useState('blue');

    useEffect(() => {
        if(progressVal > 80 && progressVal <= 100) {
            setBgColor('green');
        } else if (progressVal > 40 && progressVal <= 80) {
            setBgColor('orange');
        } else {
            setBgColor('red');
        }
    },[progressVal]);

    const getColor = () => {
        if(progressVal > 80 && progressVal <= 100) {
            return 'green'
        } else if (progressVal > 40 && progressVal <= 80) {
            return 'orange'
        } else {
            return 'red'
        }
    } 

    return (
        <>
             {/* <div className="labels-box">
                <div className="column-box">
                    <div className="icon-box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style={{ color: progressVal < 35 ? bgColor : undefined}}>
                            <path fill="currentColor" d="M13 15.6c.3.2.7.2 1 0l5-2.9v.3c.7 0 1.4.1 2 .4v-1.8l1-.6c.5-.3.6-.9.4-1.4l-1.5-2.5c-.1-.2-.2-.4-.4-.5l-7.9-4.4c-.2-.1-.4-.2-.6-.2s-.4.1-.6.2L3.6 6.6c-.2.1-.4.2-.5.4L1.6 9.6c-.3.5-.1 1.1.4 1.4c.3.2.7.2 1 0v5.5c0 .4.2.7.5.9l7.9 4.4c.2.1.4.2.6.2s.4-.1.6-.2l.9-.5c-.3-.6-.4-1.3-.5-2m-2 0l-6-3.4V9.2l6 3.4zm9.1-9.6l-6.3 3.6l-.6-1l6.3-3.6zM12 10.8V4.2l6 3.3zm10.5 6.1L20.4 19l2.1 2.1l-1.4 1.4l-2.1-2.1l-2.1 2.1l-1.4-1.4l2.1-2.1l-2.1-2.1l1.4-1.4l2.1 2.1l2.1-2.1z"/>
                        </svg>
                    </div>
                    <div className="icon-box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style={{ color: progressVal > 35 && progressVal < 85 ? bgColor : undefined}}>
                            <path fill="currentColor" d="M21 16.5c0 .38-.21.71-.53.88l-7.9 4.44c-.16.12-.36.18-.57.18s-.41-.06-.57-.18l-7.9-4.44A.99.99 0 0 1 3 16.5v-9c0-.38.21-.71.53-.88l7.9-4.44c.16-.12.36-.18.57-.18s.41.06.57.18l7.9 4.44c.32.17.53.5.53.88zM12 4.15l-1.89 1.07L16 8.61l1.96-1.11zM6.04 7.5L12 10.85l1.96-1.1l-5.88-3.4zM5 15.91l6 3.38v-6.71L5 9.21zm14 0v-6.7l-6 3.37v6.71z"/>
                        </svg>
                    </div>
                    <div className="icon-box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style={{ color: progressVal > 85 && progressVal <= 100 ? bgColor : undefined}}>
                            <path fill="currentColor" d="M21 16.5c0 .38-.21.71-.53.88l-7.9 4.44c-.16.12-.36.18-.57.18s-.41-.06-.57-.18l-7.9-4.44A.99.99 0 0 1 3 16.5v-9c0-.38.21-.71.53-.88l7.9-4.44c.16-.12.36-.18.57-.18s.41.06.57.18l7.9 4.44c.32.17.53.5.53.88zM12 4.15l-1.89 1.07L16 8.61l1.96-1.11zM6.04 7.5L12 10.85l1.96-1.1l-5.88-3.4zM5 15.91l6 3.38v-6.71L5 9.21zm14 0v-6.7l-6 3.37v6.71z"/>
                        </svg>
                    </div>
                </div>                
                <div className="tag-box">
                    <p>ESTADO CAJA: <span style={{ color: getColor(progressVal) }}>{progressVal}</span></p>
                </div>
            </div>
            <div className="stock-line">                
                <div className="progressab" style={{ width: `${progressVal}%`, backgroundColor: bgColor }}> </div>
            </div> */}
             <div className="labels-box">
                <div className="column-box">
                    <div className="icon-box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style={{ color: progressVal < 35 ? bgColor : undefined}}>
                            <path fill="currentColor" d="M13 15.6c.3.2.7.2 1 0l5-2.9v.3c.7 0 1.4.1 2 .4v-1.8l1-.6c.5-.3.6-.9.4-1.4l-1.5-2.5c-.1-.2-.2-.4-.4-.5l-7.9-4.4c-.2-.1-.4-.2-.6-.2s-.4.1-.6.2L3.6 6.6c-.2.1-.4.2-.5.4L1.6 9.6c-.3.5-.1 1.1.4 1.4c.3.2.7.2 1 0v5.5c0 .4.2.7.5.9l7.9 4.4c.2.1.4.2.6.2s.4-.1.6-.2l.9-.5c-.3-.6-.4-1.3-.5-2m-2 0l-6-3.4V9.2l6 3.4zm9.1-9.6l-6.3 3.6l-.6-1l6.3-3.6zM12 10.8V4.2l6 3.3zm10.5 6.1L20.4 19l2.1 2.1l-1.4 1.4l-2.1-2.1l-2.1 2.1l-1.4-1.4l2.1-2.1l-2.1-2.1l1.4-1.4l2.1 2.1l2.1-2.1z"/>
                        </svg>
                    </div>
                    <div className="icon-box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style={{ color: progressVal > 35 && progressVal < 85 ? bgColor : undefined}}>
                            <path fill="currentColor" d="M21 16.5c0 .38-.21.71-.53.88l-7.9 4.44c-.16.12-.36.18-.57.18s-.41-.06-.57-.18l-7.9-4.44A.99.99 0 0 1 3 16.5v-9c0-.38.21-.71.53-.88l7.9-4.44c.16-.12.36-.18.57-.18s.41.06.57.18l7.9 4.44c.32.17.53.5.53.88zM12 4.15l-1.89 1.07L16 8.61l1.96-1.11zM6.04 7.5L12 10.85l1.96-1.1l-5.88-3.4zM5 15.91l6 3.38v-6.71L5 9.21zm14 0v-6.7l-6 3.37v6.71z"/>
                        </svg>
                    </div>
                    <div className="icon-box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style={{ color: progressVal > 85 && progressVal <= 100 ? bgColor : undefined}}>
                            <path fill="currentColor" d="M21 16.5c0 .38-.21.71-.53.88l-7.9 4.44c-.16.12-.36.18-.57.18s-.41-.06-.57-.18l-7.9-4.44A.99.99 0 0 1 3 16.5v-9c0-.38.21-.71.53-.88l7.9-4.44c.16-.12.36-.18.57-.18s.41.06.57.18l7.9 4.44c.32.17.53.5.53.88zM12 4.15l-1.89 1.07L16 8.61l1.96-1.11zM6.04 7.5L12 10.85l1.96-1.1l-5.88-3.4zM5 15.91l6 3.38v-6.71L5 9.21zm14 0v-6.7l-6 3.37v6.71z"/>
                        </svg>
                    </div>
                </div>       
                <div className="stock-line">                
                    <div className="progressab" style={{ width: `${progressVal}%`, backgroundColor: bgColor }}> </div>
                </div>         
                <div className="tag-box">
                    <p>ESTADO CAJA: <span style={{ color: getColor(progressVal) }}>{progressVal < 35 ? 'Sin caja' : progressVal+`%`}</span></p>
                </div>
            </div>            

        </>             
    )
}

export default ProgressBarBox
