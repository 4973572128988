const TobMenu = () => {
    return (
        <div className="top-menu rethink-sans-regular">
            <p className="divider-gray py-8 pr-16">Bogotá - Colombia</p>
            <p className="divider-gray py-8 pr-16 pl-16">315 544 1577</p>
            <p className="py-8 pl-16">320 951 4313</p>
        </div>
    )
}

export default TobMenu;